<template>
  <content-with-sidebar
      v-if="Object.keys(personDetail).length"
      class="cws-container cws-sidebar-right blog-wrapper"
  >

    <!-- content -->
    <div class="blog-detail-wrapper">
      <b-row>
        <!-- blogs -->
        <b-col cols="12">
          <b-card
              img-top
              img-alt="Актер"
              :title="`${personDetail.name_ru} / ${personDetail.name_en}`"
          >
            <b-row>
              <b-col
                  cols="12"
                  md="3"
                  class="d-flex align-items-start justify-content-around mb-2 mb-md-0"
              >
                <div class="d-flex align-items-center justify-content-around">
                  <b-img
                      :src="personDetail.poster"
                      alt="Фото"
                      class="product-img"
                      fluid
                  />
                </div>
              </b-col>
              <b-col
                  cols="12"
                  md="9"
                  class="d-flex align-items-center justify-content-around mb-2 mb-md-0"
              >
                <b-card
                    title="Фильмография"
                    class="col-md-12"
                >
                  <b-row>
                    <b-col md="12">
                      <!--                      <table class="mt-2 mt-xl-0 w-100">
                                              <tr>
                                                <th class="pb-50">
                                                  <feather-icon
                                                      icon="UserIcon"
                                                      class="mr-75"
                                                  />
                                                  <span class="font-weight-bold">Слоган</span>
                                                </th>
                                                <td class="pb-50">
                                                  {{ movieDetail.tagline }}
                                                </td>
                                              </tr>
                                              <tr>
                                                <th class="pb-50">
                                                  <feather-icon
                                                      icon="CheckIcon"
                                                      class="mr-75"
                                                  />
                                                  <span class="font-weight-bold">Страны</span>
                                                </th>
                                                <td class="pb-50 text-capitalize">
                                                  {{ movieDetail.countries }}
                                                </td>
                                              </tr>
                                              <tr>
                                                <th class="pb-50">
                                                  <feather-icon
                                                      icon="StarIcon"
                                                      class="mr-75"
                                                  />
                                                  <span class="font-weight-bold">КП</span>
                                                </th>
                                                <td class="pb-50 text-capitalize">
                                                  {{ movieDetail.rating_kp }} <span class="small">{{ movieDetail.votes_kp }}</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th class="pb-50">
                                                  <feather-icon
                                                      icon="FlagIcon"
                                                      class="mr-75"
                                                  />
                                                  <span class="font-weight-bold">IMDB</span>
                                                </th>
                                                <td class="pb-50">
                                                  {{ movieDetail.rating_imdb }} <span class="small">{{ movieDetail.votes_imdb }}</span>
                                                </td>
                                              </tr>
                                            </table>-->
                      <b-tabs
                          pills
                      >
                        <!-- Tab: Актеры -->
                        <b-tab active>
                          <template #title>
                            <feather-icon
                                icon="UserIcon"
                                size="16"
                                class="mr-0 mr-sm-50"
                            />
                            <span class="d-none d-sm-inline">Актер</span>
                          </template>

                          <div
                              v-for="(movie,index) in personDetail.movies.ACTOR"
                              :key="movie.filmId"
                              class="d-flex align-items-center"
                              :class="index == 0 ? 'mt-2' : 'mt-1'"
                          >
                            <b-avatar
                                v-if="movie.db"
                                :src="movie.db.poster"
                                class="mr-50"
                                size="40"
                            />
                            <div class="user-page-info">
                              <h6 class="mb-0">
                                <feather-icon v-if="movie.general" icon="KeyIcon"/>
                                {{ movie.nameRu ? movie.nameRu : movie.nameEn }}
                                <span v-if="movie.db">({{ movie.db.year }})</span>
                              </h6>
                              <small class="text-muted">{{ movie.nameEn }}</small>
                            </div>
                            <span class="font-weight-bold font-large-1 ml-1">
                              <span
                                  :class="(movie.rating < 5) ? 'text-danger' : (movie.rating < 7) ? 'text-gray' : 'text-success'"
                              >
                                {{ movie.rating }}
                              </span>
                            </span>
                            <div class="d-flex justify-content-start ml-auto align-items-center">
                              <div
                                  v-if="movie.db"
                                  class="mr-1"
                              >
                                {{ movie.db.votes_kp }}
                              </div>
                              <b-button
                                  v-if="!movie.db"
                                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                  variant="success"
                                  class="btn-icon"
                                  size="sm"
                                  @click="addMovie(movie)"
                                  :disabled="(disabled===movie.filmId)"
                              >
                                <feather-icon icon="UserPlusIcon"/>
                              </b-button>
                              <b-button
                                  v-else
                                  :to="{name: 'movie-view', params: { id: movie.db.id }}"
                                  variant="primary"
                                  class="btn-icon"
                                  size="sm"
                              >
                                <feather-icon icon="ArrowRightCircleIcon"/>
                              </b-button>
                              <b-button
                                  :href="`https://kinopoisk.ru/film/${movie.filmId}`"
                                  variant="warning"
                                  class="btn-icon ml-1"
                                  size="sm"
                              >
                                <feather-icon icon="CastIcon"/>
                              </b-button>
                            </div>

                          </div>
                        </b-tab>

                        <!-- Tab: Режиссеры -->
                        <b-tab>
                          <template #title>
                            <feather-icon
                                icon="InfoIcon"
                                size="16"
                                class="mr-0 mr-sm-50"
                            />
                            <span class="d-none d-sm-inline">Режиссер</span>
                          </template>

                          <div
                              v-for="(movie,index) in personDetail.movies.DIRECTOR"
                              :key="movie.filmId"
                              class="d-flex align-items-center"
                              :class="index == 0 ? 'mt-2' : 'mt-1'"
                          >
                            <b-avatar
                                v-if="movie.db"
                                :src="movie.db.poster"
                                class="mr-50"
                                size="40"
                            />
                            <div class="user-page-info">
                              <h6 class="mb-0">
                                <feather-icon v-if="movie.general" icon="KeyIcon"/>
                                {{ movie.nameRu ? movie.nameRu : movie.nameEn }}
                                <span v-if="movie.db">({{ movie.db.year }})</span>
                              </h6>
                              <small class="text-muted">{{ movie.nameEn }}</small>
                            </div>
                            <span class="font-weight-bold font-large-1 ml-1">
                              <span
                                  :class="(movie.rating < 5) ? 'text-danger' : (movie.rating < 7) ? 'text-gray' : 'text-success'"
                              >
                                {{ movie.rating }}
                              </span>
                            </span>
                            <div class="d-flex justify-content-start ml-auto align-items-center">
                              <div
                                  v-if="movie.db"
                                  class="mr-1"
                              >
                                {{ movie.db.votes_kp }}
                              </div>
                              <b-button
                                  v-if="!movie.db"
                                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                  variant="success"
                                  class="btn-icon"
                                  size="sm"
                                  @click="addMovie(movie)"
                                  :disabled="(disabled===movie.filmId)"
                              >
                                <feather-icon icon="UserPlusIcon"/>
                              </b-button>
                              <b-button
                                  v-else
                                  :to="{name: 'movie-view', params: { id: movie.db.id }}"
                                  variant="primary"
                                  class="btn-icon"
                                  size="sm"
                              >
                                <feather-icon icon="ArrowRightCircleIcon"/>
                              </b-button>
                              <b-button
                                  :href="`https://kinopoisk.ru/film/${movie.filmId}`"
                                  variant="warning"
                                  class="btn-icon ml-1"
                                  size="sm"
                              >
                                <feather-icon icon="CastIcon"/>
                              </b-button>
                            </div>
                          </div>
                        </b-tab>
                      </b-tabs>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>


            <div class="my-1 py-25">
              <b-link
                  v-for="tag in blogDetail.blog.tags"
                  :key="tag"
              >
                <b-badge
                    pill
                    class="mr-75"
                    :variant="tagsColor(tag)"
                >
                  {{ tag }}
                </b-badge>
              </b-link>
            </div>
            <!-- eslint-disable vue/no-v-html -->
            <div
                class="blog-content"
                v-html="blogDetail.blog.content"
            />

            <!-- user commnets -->
            <b-media
                v-for="user in blogDetail.blog.UserComment"
                :key="user.avatar"
                no-body
            >
              <b-media-aside>
                <b-avatar
                    size="60"
                    :src="user.avatar"
                />
              </b-media-aside>
              <b-media-body>
                <h6 class="font-weight-bolder">
                  {{ user.fullName }}
                </h6>
                <b-card-text>
                  {{ user.comment }}
                </b-card-text>
              </b-media-body>
            </b-media>
            <!-- eslint-enable -->
            <hr class="my-2">

            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="d-flex align-items-center mr-1">
                  <b-link class="mr-50">
                    <feather-icon
                        icon="MessageSquareIcon"
                        size="21"
                        class="text-body"
                    />
                  </b-link>
                  <b-link>
                    <div class="text-body">
                      {{ kFormatter(blogDetail.blog.comments) }}
                    </div>
                  </b-link>
                </div>
                <div class="d-flex align-items-center">
                  <b-link class="mr-50">
                    <feather-icon
                        size="21"
                        icon="BookmarkIcon"
                        class="text-body"
                    />
                  </b-link>
                  <b-link>
                    <div class="text-body">
                      {{ kFormatter(blogDetail.blog.bookmarked) }}
                    </div>
                  </b-link>
                </div>
              </div>

              <!-- dropdown -->
              <div class="blog-detail-share">
                <b-dropdown
                    variant="link"
                    toggle-class="p-0"
                    no-caret
                    right
                >
                  <template #button-content>
                    <feather-icon
                        size="21"
                        icon="Share2Icon"
                        class="text-body"
                    />
                  </template>
                  <b-dropdown-item
                      v-for="icon in socialShareIcons"
                      :key="icon"
                      href="#"
                  >
                    <feather-icon
                        :icon="icon"
                        size="18"
                    />
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <!--/ dropdown -->
            </div>
          </b-card>
        </b-col>
        <!--/ blogs -->
      </b-row>
      <!--/ blogs -->
    </div>
    <!--/ content -->

    <!-- sidebar -->
    <div
        slot="sidebar"
        class="blog-sidebar py-2 py-lg-0"
    >
      <!-- input search -->
      <b-form-group class="blog-search">
        <b-input-group class="input-group-merge">
          <b-form-input
              id="search-input"
              v-model="search_query"
              placeholder="Search here"
          />
          <b-input-group-append
              class="cursor-pointer"
              is-text
          >
            <feather-icon
                icon="SearchIcon"
            />
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <!--/ input search -->

      <!-- recent posts -->
      <div class="blog-recent-posts mt-3">
        <h6 class="section-label mb-75">
          Recent Posts
        </h6>
        <b-media
            v-for="(recentpost,index) in blogSidebar.recentPosts"
            :key="recentpost.img"
            no-body
            :class="index? 'mt-2':''"
        >
          <b-media-aside class="mr-2">
            <b-link>
              <b-img
                  :src="recentpost.img"
                  :alt="recentpost.img.slice(6)"
                  width="100"
                  rounded
                  height="70"
              />
            </b-link>
          </b-media-aside>
          <b-media-body>
            <h6 class="blog-recent-post-title">
              <b-link class="text-body-heading">
                {{ recentpost.title }}
              </b-link>
            </h6>
            <span class="text-muted mb-0">
              {{ recentpost.createdTime }}
            </span>
          </b-media-body>
        </b-media>
      </div>
      <!--/ recent posts -->

      <!-- categories -->
      <div class="blog-categories mt-3">
        <h6 class="section-label mb-1">
          Categories
        </h6>

        <div
            v-for="category in blogSidebar.categories"
            :key="category.icon"
            class="d-flex justify-content-start align-items-center mb-75"
        >
          <b-link>
            <b-avatar
                rounded
                size="32"
                :variant="tagsColor(category.category)"
                class="mr-75"
            >
              <feather-icon
                  :icon="category.icon"
                  size="16"
              />
            </b-avatar>
          </b-link>
          <b-link>
            <div class="blog-category-title text-body">
              {{ category.category }}
            </div>
          </b-link>
        </div>
      </div>
      <!--/ categories -->
    </div>
  </content-with-sidebar>
</template>

<script>
import {
  BFormInput, BMedia, BAvatar, BMediaAside, BMediaBody, BImg, BLink, BFormGroup, BInputGroup, BInputGroupAppend,
  BCard, BRow, BCol, BBadge, BCardText, BDropdown, BDropdownItem, BForm, BFormTextarea, BFormCheckbox, BButton,
  BTab,
  BTabs,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {kFormatter} from '@core/utils/filter'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import router from "@/router";

export default {
  components: {
    BFormInput,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BBadge,
    BCardText,
    BDropdown,
    BForm,
    BDropdownItem,
    BFormTextarea,
    BFormCheckbox,
    BButton,
    BTab,
    BTabs,
    ContentWithSidebar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      search_query: '',
      commentCheckmark: '',
      personDetail: {},
      blogDetail: [],
      blogSidebar: {},
      socialShareIcons: [
        'GithubIcon',
        'GitlabIcon',
        'FacebookIcon',
        'TwitterIcon',
        'LinkedinIcon',
      ],
      disabled: 0,
    }
  },
  created() {
    let id = router.currentRoute.params.id
    this.$http.get(`/person/${id}?with_movies=1`).then(res => {
      this.personDetail = res.data.data
    })
    this.$http.get('/blog/list/data/detail').then(res => {
      this.blogDetail = res.data
    })
    this.$http.get('/blog/list/data/sidebar').then(res => {
      this.blogSidebar = res.data
    })
  },
  metaInfo: {
    title: 'Своя Игра ФНФ',
  },
  methods: {
    kFormatter,
    tagsColor(tag) {
      if (tag === 'Quote') return 'light-info'
      if (tag === 'Gaming') return 'light-danger'
      if (tag === 'Fashion') return 'light-primary'
      if (tag === 'Video') return 'light-warning'
      if (tag === 'Food') return 'light-success'
      return 'light-primary'
    },
    addMovie(movie) {
      this.disabled = movie.filmId
      this.$http.post(`/movie`, {kp_id: movie.filmId, 'title_ru': movie.nameRu}).then(res => {
        if (res.data.data) {
          this.$http.get(`/person/${router.currentRoute.params.id}?with_movies=1`).then(res => {
            this.personDetail = res.data.data
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
